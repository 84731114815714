import { Col, Row } from 'antd';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { ImageWrapper } from './styled';

interface Props {
  imageData: string[];
}

const ImagesRowWithLightbox = ({ imageData }: Props) => {
  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  return (
    <>
      <Row justify="center">
        {imageData.map((image, index) => (
          <Col>
            <ImageWrapper
              key={index}
              onClick={() => {
                setPhotoIndex(index);
                setLightboxOpen(true);
              }}
            >
              <img style={{ maxWidth: 380 }} src={image} />
            </ImageWrapper>
          </Col>
        ))}
      </Row>
      {lightboxOpen && (
        <Lightbox
          mainSrc={imageData[photoIndex]}
          nextSrc={imageData[(photoIndex + 1) % imageData.length]}
          prevSrc={imageData[(photoIndex + imageData.length - 1) % imageData.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + imageData.length - 1) % imageData.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imageData.length)}
        />
      )}
    </>
  );
};

export default ImagesRowWithLightbox;
