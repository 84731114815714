import styled from 'styled-components';

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  display: none;
  :hover {
    opacity: 0.8;
    cursor: zoom-in;
  }
  :nth-child(1) {
    display: initial;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.sm}px) {
    display: initial;
    :nth-child(3) {
      display: none;
    }
    width: 45%;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    width: 30%;
    :nth-child(3) {
      display: initial;
    }
  }
`;

export const ImageRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 200px;
`;
