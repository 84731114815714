import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface ImageProps extends ThemeProps<DefaultTheme> {
  imageLink: string;
  orientation?: Orientation;
}

interface OrientationProps extends ThemeProps<DefaultTheme> {
  orientation?: Orientation;
}

export enum Orientation {
  LEFT = 'left',
  RIGHT = 'right',
}

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 130px;
`;

export const SectionWithBgImage = styled.section`
  background-image: none;
  background-repeat: no-repeat;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    padding: 50px 0px;
    margin-bottom: 50px;
    background-position: ${({ orientation }: ImageProps) => (orientation === Orientation.RIGHT ? '95% 10%' : '5% 10%')};
    background-size: 80%;
    background-image: url(${({ imageLink }: ImageProps) => imageLink});
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    background-size: 50%;
  }
`;

export const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  a {
    align-self: flex-start;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    align-items: initial;
    margin-bottom: 50px;
  }
`;

export const ArticleContainer = styled.div`
  display: inline-flex;
  max-width: 100%;
  flex-direction: column;
  align-self: ${({ orientation }: OrientationProps) => (orientation === Orientation.RIGHT ? 'flex-end' : 'flex-start')};
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    max-width: 66%;
  }
  h1,
  h2 {
    align-self: center;
    @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
      align-self: flex-start;
    }
  }
`;

export const TextWrapper = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: 50px;
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  display: initial;
  margin-bottom: 50px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    display: none;
  }
`;

export const Separator = styled.div`
  margin-bottom: 300px;
`;
