import Footer from 'components/CommonComponents/Footer';
import NavigationBar from 'components/CommonComponents/NavigationBar';
import { RedLink } from 'components/CommonComponents/RedButtons';
import SEO from 'components/CommonComponents/SEO';
import { MainTitleText } from 'components/CommonComponents/Titles/styled';
import { StyledSection } from 'components/CommonComponents/Wrappers/styled';
import ImagesRowWithLightbox from 'components/PageOffice/ImagesRowWithLightbox';
import {
  ArticleContainer,
  HeaderImage,
  MainContainer,
  Orientation,
  ParagraphContainer,
  SectionWithBgImage,
  Separator,
  TextWrapper,
} from 'components/PageOffice/styled';
import { GlobalStyle } from 'constants/globalTheme';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import 'react-image-lightbox/style.css';

const pageQuery = graphql`
  {
    allPositionsYaml(filter: { slug: { eq: "sales-manager" } }) {
      nodes {
        slug
        sneak {
          cs
        }
        text {
          cs
        }
        description {
          header {
            cs
          }
          text {
            cs
          }
        }
      }
    }
    officeYaml {
      buttonText {
        cs
      }
      paragraph {
        heading {
          cs
        }
        text {
          cs
        }
        backgroundImage {
          publicURL
        }
        images {
          publicURL
        }
      }
    }
  }
`;

const Offices = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery).officeYaml;

  const firstParImages = data.paragraph[0].images.map((a: any) => a.publicURL);
  const secondParImages = data.paragraph[1].images.map((a: any) => a.publicURL);
  return (
    <>
      <SEO title="Kanceláře" />
      <GlobalStyle />

      <NavigationBar />
      <MainContainer>
        <SectionWithBgImage imageLink={data.paragraph[0].backgroundImage.publicURL} orientation={Orientation.RIGHT}>
          <StyledSection as="div">
            <HeaderImage src={data.paragraph[0].backgroundImage.publicURL} />
            <ParagraphContainer>
              <ArticleContainer orientation={Orientation.LEFT}>
                <MainTitleText>{data.paragraph[0].heading[locale]}</MainTitleText>
                <TextWrapper>{data.paragraph[0].text[locale]}</TextWrapper>
              </ArticleContainer>
              <RedLink text={data.buttonText[locale]} to={'/contact'} />
            </ParagraphContainer>
          </StyledSection>
        </SectionWithBgImage>
        <StyledSection style={{ maxWidth: '90%', paddingBottom: 50 }}>
          <ImagesRowWithLightbox imageData={firstParImages} />
        </StyledSection>
        <SectionWithBgImage imageLink={data.paragraph[1].backgroundImage.publicURL} orientation={Orientation.LEFT}>
          <StyledSection as="div">
            <ParagraphContainer>
              <ArticleContainer orientation={Orientation.RIGHT}>
                <MainTitleText as="h2">{data.paragraph[1].heading[locale]}</MainTitleText>
                <TextWrapper>{data.paragraph[1].text[locale]}</TextWrapper>
              </ArticleContainer>
            </ParagraphContainer>
          </StyledSection>
        </SectionWithBgImage>
        <StyledSection style={{ maxWidth: '90%' }}>
          <ImagesRowWithLightbox imageData={secondParImages} />
        </StyledSection>
        <Separator />
      </MainContainer>
      <Footer />
    </>
  );
};

export default Offices;
